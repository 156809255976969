

const init = function() {
    window.site.doc.on('click', '.js-dynamic-pager-page', function(event) {
        event.preventDefault();
        getPage($(this))
    });
}

const getPage = function(obj) {
    var urlPath = obj.attr('href');
    obj.closest('.m-paginated__container').before('<div class="loader"></div>');
    obj.closest('.m-paginated__container').css('display', 'none');
    return $.ajax({
        type: "GET",
        url: urlPath,
        success: function(result) {
            obj.closest('.loader').remove();
            obj.closest('.m-paginated').replaceWith(result);
        }
    });
}

export default init;
export { getPage }
